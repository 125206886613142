<template>
  <div class="task-details-map">
    <gmap-map
      ref="map"
      :center="getPosition(selectedTask)"
      :zoom="zoom"
      :options="mapOptions">
      <map-marker
        v-if="courierPosition"
        :key="`task_details_map_marker_trip_${trip.id}`"
        :position="courierPosition"
        :type="MAP_MARKER_TYPES.COURIER"
        :src="getIconSrc(MAP_MARKER_TYPES.COURIER)"
        :label="courierName"
        :zIndex="0"/>
      <map-marker
        v-for="(task, i) in tasks"
        :key="`task_details_map_marker_${task.orderIdentifier}`"
        :position="getPosition(task)"
        :type="getMarkerType(task)"
        :src="getIconSrc(getMarkerType(task), task.startTime)"
        :label="(i + 1).toString()"
        :zIndex="(task.id === selectedTask.id ? tasks.length : i) + 1"/>
    </gmap-map>
  </div>
</template>

<script>
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import { MAP_MARKER_TYPES, getIconSrc } from '@js/map-marker-utils'
import MapMarker from '@components/MapMarker'

// const MAP_MARKER_SVG_NOTATION = 'M12.0006 1.66602C17.6812 1.66602 22.2863 6.17048 22.2863 11.727C22.2863 16.0219 16.1423 24.196 13.3514 27.6882C13.0265 28.095 12.5282 28.3327 12.0006 28.3327C11.4729 28.3327 10.9746 28.095 10.6497 27.6882C7.85884 24.1982 1.71484 16.0242 1.71484 11.7292C1.71424 9.06052 2.79764 6.5009 4.72666 4.6136C6.65565 2.72632 9.27222 1.66602 12.0006 1.66602Z'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: ''
  }
})

export default {
  name: 'task-details-map',
  components: {
    MapMarker
  },
  props: {
    tasks: {
      type: Array,
      required: true
    },
    selectedTask: {
      type: Object,
      required: true
    },
    trip: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      MAP_MARKER_TYPES,
      zoom: 15,
      mapOptions: {
        minZoom: 11,
        disableDefaultUI: true
      }
    }
  },
  computed: {
    courierPosition() {
      if (this.trip && this.trip.latitude && this.trip.longitude) {
        return {
          lat: this.trip.latitude,
          lng: this.trip.longitude
        }
      }
      return null
    },
    courierName() {
      if (this.trip.fulfilmentUser) {
        return this.trip.fulfilmentUser.name
      }
      return this.trip.id
    }
  },
  methods: {
    getPosition(task) {
      return {
        lat: task.latitude,
        lng: task.longitude
      }
    },
    getMarkerType(task) {
      if (task.id === this.selectedTask.id) {
        return MAP_MARKER_TYPES.MAIN
      }
      return MAP_MARKER_TYPES.SIDE
    },
    getIconSrc(type = null, startTime = null) {
      return getIconSrc(type, startTime)
    }
  }
}
</script>

<style lang="scss" scoped>
.task-details-map {
  width: 100%;
  height: 200px;

  .vue-map-container {
    width: 100%;
    height: 100%;

    &::v-deep {
      @import '~@css/map-markers';
    }
  }
}
</style>
