<template>
  <!-- wrap gmap-custom-marker in a div to prevent a [Failed to execute 'insertBefore' on 'Node'] error -->
  <div>
    <gmap-custom-marker
      :cssPosition="true"
      :marker="position"
      :zIndex="zIndex"
      @mouseover.native="() => $emit('mouseover')"
      @mouseout.native="() => $emit('mouseout')"
      @click.native="() => $emit('click')">
      <img
        :src="src"
        :class="`marker-icon-${type}`">
      <span :class="`marker-label-${type}`">
        {{ label }}
      </span>
    </gmap-custom-marker>
  </div>
</template>

<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker'

export default {
  name: 'map-marker',
  components: {
    GmapCustomMarker
  },
  props: {
    position: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: null
    },
    src: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    zIndex: {
      type: Number,
      default: 50
    }
  }
}
</script>
